.paper {
  padding: 16px;
  height: 200px;
  width: 300px;
}

.ty-error-title {
  color: red;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.ty-error-error {
  color: black;
  text-transform: none;
  text-align: center;
  margin-top: 30px;
}

.div-wrapper-button {
  text-align: center;
}

.button {
  margin-top: 70px;
  background: pink;
  border-style: solid;
  border-width: 2px;
  border-color: red;
  color: red;
  border-radius: 10px;
  height: 40px;
  width: 150px;
  text-transform: none;
  font-size: 16px;
  font-weight: bold;
}
