.users-no-users {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 0.95em;
    margin-top: 12px;
  }
}

.users-no-users:before {
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  animation: users-no-users 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  opacity: 0;
}
.users-no-users h2 {
  font-size: 0.95em;
  margin-top: 12px;
}

.users-no-users__icon {
  position: relative;
  margin: 25px auto 0;
  width: 50px;
  height: 32px;
  border-radius: 0 0 4px 4px;
  animation: users-no-users__icon 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 16px 25px;
  }
  &:before {
    top: -100%;
    left: 0px;
  }
  &:after {
    top: 0;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.075);
  }
}

.users-no-users__paper {
  background: #fff;
  width: 40px;
  height: 0px;
  margin: 0 auto;
  border-radius: 2px;
  position: absolute;
  left: 5px;
  bottom: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  animation: users-no-users__paper 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 6px;
    background: #e3f1fc;
    height: 2px;
    border-radius: 2px;
  }
  &:before {
    top: 7px;
    width: 16px;
  }
  &:after {
    right: 6px;
    top: 14px;
    box-shadow: 0 4px 0 #e3f1fc, 0 8px 0 #e3f1fc, 0 12px 0 #e3f1fc, 0 20px 0 #e3f1fc;
  }
}

@keyframes users-no-users__icon {
  0%,
  50% {
    transform: translateY(15px);
    opacity: 0;
  }
  50%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes users-no-users__paper {
  0%,
  60% {
    height: 15px;
    bottom: 0;
  }
  75%,
  86%,
  100% {
    height: 50px;
  }
  80% {
    height: 55px;
  }
}

@keyframes users-no-users {
  0%,
  60% {
    width: 20px;
    height: 20px;
    opacity: 0;
  }
  75% {
    width: 200px;
    height: 200px;
    opacity: 0.2;
  }
  80%,
  100% {
    opacity: 0;
  }
}
